import { html } from 'Components';
import { Fetcher, Session } from 'Utils';
import emojiFlags from 'emoji-flags';
import TableBasic from 'Components/abstract/TableBasic.js'

class SessionPage extends TableBasic {

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title:'Current sessions',
            refresh:'Refresh',
            cancel:'Cancel',
            add:'Add a product',
            edit:'Edit a product',
            delete:'Delete permanently',
            no_sessions:'No sessions found',
            save:'Save'
          },
        },
        french:{
          translation: {
            title:'Sessions en cours',
            refresh:'Rafraîchir',
            cancel:'Annuler',
            add:'Ajouter un produit',
            edit:'Modifier un produit',
            delete:'Supprimer définitivement',
            no_sessions:'Aucune session en cours',
            save:'Enregistrer'
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.debug = false;
    this.apiEndpoint = 'private/admin/system/sessions';
    this.joinRoom = this.joinRoom.bind(this);
    this.onSessionChange = this.onSessionChange.bind(this)
    this.socketOptions = { room:'sessions', event:'sessionChange' };
  }
  
  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('socket-reconnected', this.joinRoom);
    this.joinRoom();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('socket-reconnected', this.joinRoom);
    Session.sockets.boss.leave(this.socketOptions);
  }

  joinRoom() {
    Session.sockets.boss.onMessages(this.socketOptions, this.onSessionChange);
  }

  onSessionChange(data) {
    const { event, id, session } = data;
    switch (event) {
      case 'set':
      case 'touch':
        this.updateSession(id, session);
        break;
      case 'destroy':
        this.removeSession(id);
        break;
    }
  }

  updateSession(id, session) {
    if (!this.sessions) return;
    const index = this.sessions.findIndex(s => s._id === id);
    if (index !== -1) {
      this.sessions[index] = session;
    } else {
      this.sessions.push(session);
    }
    this.requestUpdate();
  }

  removeSession(id) {
    this.sessions = this.sessions.filter(s => s._id !== id);
    this.requestUpdate();
  }

  async loadData() {
    this.loading = true;
    const response = await Fetcher.get('private/admin/system/sessions');
    this.sessions = response?.data;
    this.loading = false;
  }

  async firstUpdated() {
    super.firstUpdated();
    await this.loadData();
  }

  renderLoader() {
    if (!this.loading) return;
    return html`<sl-spinner></sl-spinner>`;
  }

  renderNoData() {
    if (this.loading || this.sessions?.length) return;
    return html`<div class="center">
      ${this._tl('no_sessions')}
      <br/><br/>
    </div>`;
  }

  getFlag(countryCode) {
    const flag = emojiFlags[countryCode];
    return flag ? flag.emoji : '🏳️'; 
  }

  formatHourMinute(date) {
    return new Date(date).toLocaleTimeString();
  }

  formatTimestamp(timestamp) {
    return new Date(timestamp * 1000).toLocaleTimeString();
  }

  renderData() {
    if (!this.sessions?.length) return;

    return html`
      <table>
        <thead>
          <tr>
            <td width="20">&nbsp;</td>
            <td width="150">IP</td>
            <td width="220">Utilisateur</td>
            <td>Page</td>
            <td width="60" align="center">Dernière requête</td>
            <td width="80" align="center">Idle expires</td>
            <td width="80" align="center">Next access token</td>
            <td width="60" align="center">Win focus</td>
            <!--<td>&nbsp;</td>-->
          </tr>
        </thead>
        <tbody>
        ${this.sessions.map((data) => html`
          <tr>
            <td>${this.getFlag(data.session.geo?.country)}</td>
            <td>${data.session.ip}</td>
            <td>${data.session.userPublicData?.email ? data.session.userPublicData.email : 'anonyme'}</td>
            <td>${data.session.currentPage}</td>
            <td align="center">${this.formatHourMinute(data.session.lastSeen).toLocaleString()}</td>
            <td align="center">${this.formatHourMinute(data.expires).toLocaleString()}</td>
            <td align="center">
              ${data.session.keycloak
                ? this.formatTimestamp(data.session?.keycloak?.accessTokenExp).toLocaleString()
                : 'N/A'
              }
            </td>
            <td align="center">${data.session.hidden ? 'Non' : 'Oui'}</td>
            <!--
            <td>
              <sl-dropdown>
                <m-icon slot="trigger" name="more_vert"></m-icon>
                <sl-menu>
                  <sl-menu-item @click="${() => this.edit()}">Modifier</sl-menu-item>
                  <sl-menu-item @click="${() => this.delete()}">Supprimer</sl-menu-item>
                </sl-menu>
              </sl-dropdown>
            </td>
            -->
          </tr>
        `)}
        </tbody>
      </table>
      
    `;
  }

  render() {
    return html`
      <section-header backroute="../../" micon="bring_your_own_ip">
        ${this._tl('title')}
        <div slot="right" class="flex_button">
          ${this.renderLoader()}
        </div>
      </section-header>
      <br/>
      ${this.renderNoData()}
      ${this.renderData()}
    `;
  }

}

customElements.define('page-sessions', SessionPage);