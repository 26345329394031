import { PageElement, html, css } from 'Components';

import './translate.js';
import './list/index.js';

class AdminLanguagesPage extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .grid {
          display:grid;
          grid-template-columns: repeat(6, 1fr);
          margin-right:20px;
          gap:20px;
          font-size:0.9em;
        }

        @media (max-width: 670px) {
          .grid {
            display:grid;
            grid-template-columns: repeat(2, 1fr); /* Crée 3 colonnes de taille égale */
          }
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            internationalisation: 'Internationalization',
            languages_site: 'Website languages',
            automatic_translation: 'Automatic translation'
          },
        },
        french:{
          translation: {
            internationalisation: 'Internationalisation',
            languages_site: 'Langues du site',
            automatic_translation: 'Traduction automatique'
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.layout = '';
  }

  render() {
    return html`
      <section-header backroute="../../" micon="globe_asia">${this._tl('internationalisation')}</section-header>
      <br/>
      <div class="grid">
        <box-styled layout="${this.layout}" micon="captive_portal" aroute="/private/admin/sys/languages/list">${this._tl('languages_site')}</box-styled>
        <box-styled layout="${this.layout}" micon="translate" aroute="/private/admin/sys/languages/translate">${this._tl('automatic_translation')}</box-styled>
      </div>
      <br/>
    `;
  }

}

customElements.define('page-languages', AdminLanguagesPage);