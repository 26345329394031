import { PageElement, html, css } from 'Components';

import './email';
import './sms';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .grid {
          display:grid;
          grid-template-columns: repeat(5, 1fr); /* Crée 3 colonnes de taille égale */
          margin:10px;
          gap:20px;
        }

        box-styled[variant="warning"] {
          font-size:0.9em;
        }

        @media (max-width: 670px) {
          .grid {
            display:grid;
            grid-template-columns: repeat(2, 1fr); /* Crée 3 colonnes de taille égale */
            margin:10px;
          }
        }
      `
    ];
  }

  constructor() {
    super();
    this.layout = '';
  }

  render() {
    return html`
        <section-header micon="article" backroute="../../">Modèles</section-header>
        <br/><br/>
        <div class="grid">
          <box-styled layout="${this.layout}" micon="mail" aroute="./email">Email</box-styled>
          <box-styled layout="${this.layout}" micon="sms" aroute="./sms">SMS</box-styled>
        </div>
      </div>
    `;
  }

}

customElements.define('page-tpl', Page);