import { PageElement, html, css, formCss } from 'Components';
import { Notify, Session } from 'Utils';
import { Fetcher } from 'Utils';
import App from 'App';
import { unsafeHTML } from 'Components';

class AdminLanguagesTranslatePage extends PageElement {
  static get styles() {
    return [
      super.styles,
      formCss,
      css`
        p {
          margin-left: 20px;
        }

        sl-checkbox {
          margin:5px;
        }

        .flex {
          width:100%;
          display:flex;
          gap:20px;
        }

        .flex div {
          flex:1;
        }

        .rt_message {
          display:flex;
        }
      `
    ]
  }

    static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title:'Automatic translations',
            creation:'Creations',
            components:'Components',
            tags:'Tags',
            subjects:{
              title:'What do you want to translate ?',
              all:'All',
              tickets:'Tickets',
            },
            engines:{
              title:'Translation engine',
              libretranslate:'Libre translate (free, average quality)',
              chatgpt:'ChatGPT (paid, good quality)',
            },
            ignore_translated:'Do not translate what has already been translated',
            translate:'Translate',
            translate_confirm:'Proceed with automatic translation ?',
            translate_running:'Translation in progress, you can close this window,<br/>wait, or stop the process.',
            translate_running_title:'Translation in progress ...',
            stop:'Stop',
            close:'Close',
            continue:'Continue',
            cancel:'Cancel',
            completed:'Translation completed: {{recordsCount}} sentence(s), {{modifiedCount}} modifications(s)',
            failed:'Translation failed',
            progress:'Progress',
          }
        },
        french:{
          translation: {
            title:'Traductions automatiques',
            creation:'Créations',
            components:'Composants',
            tags:'Tags',
            subjects:{
              title:'Que désirez vous traduire ?',
              all:'Tout',
              tickets:'Tickets',
            },
            engines:{
              title:'Moteur de traduction',
              libretranslate:'Libre translate (gratuit, qualité moyenne)',
              chatgpt:'ChatGPT (payant, bonne qualité)',
            },
            ignore_translated:'Ne pas traduire ce qui a déjà été traduit',
            translate:'Traduire',
            translate_confirm:'Procéder à la traduction automatique ?',
            translate_running:'La traduction est en cours, vous pouvez fermer cette fenêtre,<br/>patienter, ou bien arrêter le processus.',
            translate_running_title:'Traduction en cours ...',
            stop:'Arrêter',
            close:'Fermer',
            cancel:'Annuler',
            continue:'Continuer', 
            completed:'Traduction terminée: {{recordsCount}} phrase(s), {{modifiedCount}} modification(s)',
            failed:'Traduction échouée',
            progress:'Progression',
          }
        }
      }
    ]
  }

  getFormData() {
    const form = this.shadowRoot.querySelector('form');
    const formData = new FormData(form);
       
    const data = {};
    formData.forEach((value, key) => {
      if (!data[key]) {
        data[key] = value;
      } else {
        if (!Array.isArray(data[key])) {
          data[key] = [data[key]];
        }
        data[key].push(value);
      }
    });

    Object.keys(data).forEach(key => {
      if (data[key] === 'on') {
        data[key] = true;
      } else if (data[key] === 'off') {
        data[key] = false;
      }
    });

    if (typeof data.subjects === 'string') {
      data.subjects = [data.subjects];
    }


    data.source = App.config.lgMain;
    data.target = this.shadowRoot.querySelector('languages-selector[name="target"]').lg;

    return data;
  }

  translateConfirm() {
    const data = this.getFormData();

    if (!data.target) {
      Notify.error('Veuillez choisir une langue cible');
      return;
    }

    if (!data.subjects?.length) {
      Notify.error('Veuillez sélectionner quoi traduire');
      return;
    }
    this.modalTranslateConfirm = this.shadowRoot.getElementById('modal-translate-confirm');
    this.modalTranslateRunning = this.shadowRoot.getElementById('modal-translate-running');
    this.modalTranslateConfirm.show();
  }

  async translate() {
    const data = this.getFormData();

    this.modalTranslateConfirm.loadingButtons();
    const response = await Fetcher.post('private/admin/queue', data);
    this.modalTranslateConfirm.resetButtons();
    this.modalTranslateConfirm.hide();
  
    if (response?.data?.id) {
      this.modalTranslateRunning.progress = 0;
      this.modalTranslateRunning.progressMessage = '';
      this.modalTranslateRunning.show();
      
      this.translateTaskId = response.data.id;

      Session.sockets.me.emit('joinRoom', this.translateTaskId);

      Session.sockets.me.off('joinedRoom');

      Session.sockets.me.on('joinedRoom', (/*room*/) => {

        Session.sockets.me.off('taskProgress');

        Session.sockets.me.on('taskProgress', message => {
          if (message.jobId === response.data.id) {
            if (message.event === 'progress') {
              const data = message.data;
              this.modalTranslateRunning.progress = data.progress;
              this.modalTranslateRunning.progressMessage = html`
                <br/>
                <div class="rt_message">
                  <div>${data.count}/${data.total}</div>
                  <div>${data.message}</div>
                </div>
              `;
              if (data.progress === 100) this.modalTranslateRunning.hide();
            } else if (message.event === 'completed') {
              Notify.success({ title:this._tl('completed', message.returnvalue) });
              this.modalTranslateRunning.resetButtons();
              this.modalTranslateRunning.hide();
            } else {
              if (message.failedReason) {
                Notify.error(message.failedReason);
              }
              this.modalTranslateRunning.resetButtons();
              this.modalTranslateRunning.hide();
            }
          }
        });
      });
    }
  }

  async translateStop() {
    this.modalTranslateRunning.loadingButtons();
    await Fetcher.delete(`private/admin/queue/${this.translateTaskId}`);
  }

  checkAll(event) {
    const checkboxes = this.shadowRoot.querySelectorAll('sl-checkbox[name="subjects"]');
    checkboxes.forEach(checkbox => { checkbox.checked = event.target.checked; });
  }

  uncheckAll() {
    const allCheckbox = this.shadowRoot.querySelector('sl-checkbox[name="subjects"][value="all"]');
    allCheckbox.checked = false;
  }

  render() {
    const size = 'small';

    return html`
      <section-header backroute="/private/admin/sys/languages" micon="translate">${this._tl('title')}</section-header>
      <br/>
      <br/>
      <form>
        <div class="flex">
          <div>
            <section-header size="medium">${this._tl('subjects.title')}</section-header>
            <p>
              <sl-checkbox size="${size}" name="subjects" value="all" @click=${this.checkAll}>${this._tl('subjects.all')}</sl-checkbox><br/><br/>
              <sl-checkbox size="${size}" checked name="subjects" value="tickets" @click=${this.uncheckAll}>${this._tl('subjects.tickets')}</sl-checkbox><br/>
            </p>
          </div>
          <div>
            <section-header size="medium">${this._tl('engines.title')}</section-header>
            <p>
              <sl-select size="small" name="engine" value="libretranslate">
                <sl-option value="libretranslate">${this._tl('engines.libretranslate')}</sl-option>
                <sl-option value="chatgpt">${this._tl('engines.chatgpt')}</sl-option>
              </sl-select>
              <br/>
              Choisissez la langue cible :
              <languages-selector name="target" value="" novalue hideCurrent></languages-selector>
              <sl-checkbox size="${size}" name="ignore_translated" checked>${this._tl('ignore_translated')}</sl-checkbox><br/>
              <br/>
              <sl-button variant="primary" @click=${this.translateConfirm}>${this._tl('translate')}</sl-button>
            </p>
          </div>
        </div>
      </form>

      <modal-dialog id="modal-translate-confirm">
        ${this._tl('translate_confirm')}<br/>
        <sl-button variant="text" slot="bt1" close="true">${this._tl('cancel')}</sl-button>
        <sl-button slot="bt2" variant="primary" @click="${this.translate}">${this._tl('continue')}</sl-button>
      </modal-dialog>

      <modal-dialog id="modal-translate-running" label="${this._tl('translate_running_title')}" progress="0">
        ${unsafeHTML(this._tl('translate_running'))}<br/>
        <sl-button variant="text" slot="bt1" close="true">${this._tl('close')}</sl-button>
        <sl-button slot="bt2" variant="danger" @click="${this.translateStop}">Arrêter</sl-button>
      </modal-dialog>
    `;
  }
}

customElements.define('page-mass-translate', AdminLanguagesTranslatePage);
