import { PageElement, html, css } from 'Components';

class MonitorPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      css`
        iframe {
          width: 100%;
          height: 80vh;
          border: none;
        }
      `
    ]
  }

  constructor() {
    super();
  }

  render() {
    return html`
      <section-header backroute="../../" micon="avg_time">
        Santé du serveur
      </section-header>
      <br/>
      <iframe frameborder="0" src="/netdata/"></iframe>
    `;
  }

}

customElements.define('page-admin-srv-monitor', MonitorPage);