import { html, css } from 'Components';
import DrawerForm from 'Components/abstract/DrawerForm.js';
import { Lang, Session, Fetcher } from 'Utils';

import 'Components/business/select-customers-nodetype.js';
import config from './config.js';

class AdminCustomerSMSTemplateEdit extends DrawerForm {
  
  static get styles() {
    return [
      super.styles,
      css`
        label {
          font-size:0.9em;
          padding-left:5px;
        }

        .padding {
          padding-left:5px;
        }

        box-styled[variant="warning"] {
          font-size:0.9em;
        }

        pre {
          font-size:0.6em;
        }
      `
    ]
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            cancel:'Cancel',
            add:'Add a template',
            edit:'Edit',
            save:'Save',
            send:'Send'
          },
        },
        french:{
          translation: {
            cancel:'Annuler',
            add:'Ajouter un modèle',
            edit:'Modifier',
            save:'Enregistrer',
            send:'Envoyer'
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.apiEndpoint = config.apiEndpoint;
    this.urlVar = config.urlVar;
    this.eventUpdated = config.eventUpdated;
    this.itemTitleField = config.itemTitleField;
    this.getTranslatePayload = this.getTranslatePayload.bind(this);
  }

  getItemTitle() {
    return this.item.name;
  }

  getTranslatePayload() {
    const payload = {
      content: Lang.lookup(this.item, 'content'),
    }
    return payload;
  }

  onTranslated(ev) {
    this._lookupField('content', 'en').value = ev.detail.content || '';
    this.compareFields();
  }

  handleSubmitError(input, response) {
    if (!input) {
      console.warn('Field not found', response.field);
      return;
    }

    let msg = 'Valeur obligatoire';
    if (response.reason === 'MIN_LENGTH') {
      msg = `Longueur minimum de ${response.minValue} caractères`;
    }

    if (input.setCustomValidity) input.setCustomValidity(msg);
    if (input.reportValidity) input.reportValidity();
  }

  editorUpdated() {
    console.log('editorUpdated');
    this.compareFields();
    /*
    if (!ev.detail.reset) {
      this.item.content = ev.detail;
      this.compareFields();
    } 
    */
  }

  displaySelectRecipients(item) {
    return `${item.firstname} ${item.lastname} - ${item.mobile_primary || 'N/A'}`;
  }
  
  handleTestValidation() {
    const testAs = this.qs('[name="test_as"]');
    const testRecipient = this.qs('[name="test_recipient"]');
    const testLanguage = this.qs('languages-selector')
    const bt = this.qs('#test_button');
    if (testAs.value && testRecipient.value && testLanguage.lg) {
      bt.removeAttribute('disabled');
    } else {
      bt.setAttribute('disabled', true);
    }
  }

  async handleTest() {
    const bt = this.qs('#test_button');
    bt.loading = true;
    const url = `${this.apiEndpoint}/${this.item._id}/test`;
    const response = await Fetcher.post(url, {
      test_as_id: this.qs('[name="test_as"]').value,
      test_recipient: this.qs('[name="test_recipient"]').value,
      lg: this.qs('languages-selector').lg
    });
    bt.loading = false;

    if (response?.data) {
      this.qs('#modal-test-result').show();
      this.qs('#modal-test-result').querySelector('pre').textContent = JSON.stringify(response.data, null, 4);
    }

  }

  render() {    
    if (!this.item) return '';

    return html`
      <modal-drawer>
        <form>
          <br/>
          <tab-group class="main" .item=${this.item}>
            <sl-tab slot="nav" panel="general">Général</sl-tab>
            <sl-tab slot="nav" panel="template">Template</sl-tab>
            <sl-tab slot="nav" panel="test">Tester ...</sl-tab>

            <sl-tab-panel name="general">
              <sl-input size="small" name="name" class="labol" label="Nom de la template" value="${this.item.name}"></sl-input><br/>
            </sl-tab-panel>

            <sl-tab-panel name="template">
              <tab-group-lg level="lg" .item=${this.item}>
                <br/>
                <sl-textarea name="content"></sl-textarea>
              </tab-group-lg>
            </sl-tab-panel>

            <sl-tab-panel name="test">
              <div class="padding">
                ${this.pendingChanges 
                  ? html`<box-styled variant="warning">Vous avez des modifications non enregistrées.</box-styled><br/>`
                  : ''
                }
                <label>Simuler le destinataire :</label><br/>
                <select-customers-nodetype
                  name="test_as"
                  ntype="account,contact"
                  search
                  size="small"
                  sort="name"
                  .displayFields=${this.displaySelectRecipients}
                  exclude_change="true" 
                  @change="${this.handleTestValidation}"
                ></select-customers-nodetype>
                <br/>

                <label>Destinataire(s) réel(s) :</label><br/>
                <sl-input size="small" 
                  name="test_recipient"
                  exclude_change="true" 
                  value="${Session.email()}"
                  help-text="Spécifier plusieurs destinataires en les séparants par un points virgules, exemple: email1@test.com;email1@test.com"
                  @input="${this.handleTestValidation}"
                ></sl-input>
                <br/>

                <languages-selector exclude_change="true"></languages-selector>
                <br/>
                
                <sl-button id="test_button" size="small" disabled @click="${this.handleTest}">
                  <m-icon slot="suffix" name="send"></m-icon>
                  ${this._tl('send')}
                </sl-button>

              </div>
            </sl-tab-panel>

          </tab-group>
        </form>

        <sl-button slot="bt1" variant="primary" @click="${this.handleSubmit}">${this._tl('save')}</sl-button>
        <sl-button slot="bt2" variant="text" close="true">${this._tl('cancel')}</sl-button>
        
      </modal-drawer>

      <modal-dialog id="modal-test-result" label="Détail SMTP">
        <div class="padding">
          <p>Le mail a été envoyé avec succès :</p>
          <pre></pre>
        </div>
        <sl-button slot="bt2" variant="primary" close="true">OK</sl-button>
      </modal-dialog>
    `;
  }

}

customElements.define('admin-sms-template-edit', AdminCustomerSMSTemplateEdit);